<template>
    <div class="Tickets">
        <v-alert
            v-if="!showWalletLoader && getUserStatus === 'BLOCKED_PAYMENT_REQUIRED'"
            :message="$t('wallet:blocked_payment_required:message')"
            class="text-xs"
            density="compact"
            closable
            variant="outlined"
            type="error"
        >
            <p class="h3">
                {{ $t ('wallet:blocked_payment_required:title') }}
            </p>
            <p
                class="Tickets-alertMessage"
                v-html="$sanitize($t('wallet:blocked_payment_required:message'))"
            />
        </v-alert>
        <block-loader v-if="showWalletLoader" />
        <template v-else>
            <v-slide-group
                v-if="combinedProviderData().length > 1"
                show-arrows
                class="mb-4"
            >
                <v-slide-group-item
                    v-for="({ providerAccount }, index) in combinedProviderData()"
                    :key="providerAccount"
                >
                    <v-btn
                        :color="currentWalletId === index ? 'primary' : undefined"
                        :disabled="combinedProviderData().length === 1"
                        class="rounded-lg ma-1"
                        @click="currentWalletId = index"
                    >
                        {{ getFullName(providerAccount) }}
                    </v-btn>
                </v-slide-group-item>
            </v-slide-group>

            <v-window v-model="currentWalletId">
                <v-window-item
                    v-for="{ provider, providerAccount } in combinedProviderData()"
                    :key="'content-' + providerAccount.id"
                    class="pa-2"
                    :value="providerAccount"
                >
                    <div v-if="!isEmpty(providerAccount.fareMedias)">
                        <fare-profile
                            v-if="!isEmpty(provider.walletManageFareProfile)"
                            :provider="provider"
                            :provider-account="providerAccount"
                            class="Tickets-fareProfile"
                        />

                        <section
                            v-if="providerAccount.fareMedias.length > 0"
                            class="Tickets-list Tickets-fareMediaList"
                        >
                            <div class="Tickets-subtitle mb-4">
                                <icomoon-icon
                                    name="Card"
                                    class="Tickets-tabIcon"
                                />
                                <h3 class="Tickets-subtitleText">
                                    {{ $t('account:tickets:tabs:cards') }}
                                </h3>
                            </div>

                            <div
                                v-for="fareMedia in providerAccount.fareMedias"
                                :key="fareMedia.id"
                            >
                                <fare-media-item
                                    :fareMedia="fareMedia"
                                    class="Tickets-fareMediaItem"
                                    @download-fare-media-certificate-button-clicked="downloadFareMediaCertificateButtonClicked"
                                    @suspend-subscription-button-clicked="suspendSubscriptionButtonClicked"
                                    @update-fare-media="openSupportReaderModal"
                                />
                            </div>
                        </section>

                        <section v-if="!isEmpty(providersWithDevices)">
                            <div class="Tickets-subtitle my-2">
                                <icomoon-icon
                                    name="Phone"
                                    class="Tickets-tabIcon"
                                />
                                <h3 class="Tickets-subtitleText">
                                    {{ $t('account:tickets:tabs:mobile') }}
                                </h3>
                            </div>

                            <div
                                v-for="providerWithDevices in providersWithDevices"
                                :key="providerWithDevices.id"
                            >
                                <device-list :provider="providerWithDevices" />

                                <p
                                    v-if="providerWithDevices.walletAddFareMedia"
                                    class="Tickets-addCard"
                                >
                                    <v-btn
                                        color="primary"
                                        variant="text"
                                        @click="setShowFareMediaAddModal"
                                    >
                                        <v-icon class="IconCard Tickets-addCardIcon" />
                                        {{ providerWithDevices.walletAddFareMedia.label }}
                                    </v-btn>
                                </p>
                            </div>
                        </section>

                        <template v-if="getWallet.hasETickets()">
                            <div class="Tickets-subtitle my-2">
                                <icomoon-icon
                                    name="QRCode"
                                    class="Tickets-tabIcon"
                                />
                                <h3 class="Tickets-subtitleText">
                                    {{ $t('account:tickets:tabs:etickets') }}
                                </h3>
                            </div>

                            <ul
                                v-if="providerAccount.eTickets.length > 0 && provider.walletOptions.type === 'MOBILE'"
                                class="Tickets-list Tickets-eTicketList"
                            >
                                <e-ticket-item
                                    v-for="(eTicket, index) in providerAccount.eTickets"
                                    :key="`e-ticket-${provider.id}-${providerAccount.id}-${index}`"
                                    :eTicket="eTicket"
                                    class="Tickets-eTicketListItem"
                                />
                            </ul>

                            <ul
                                v-if="getNonEmptyWalletProviderAccounts.length > 0"
                                class="Tickets-list Tickets-providerAccountList"
                            >
                                <provider-account-item
                                    v-for="providerAccountNonEmptyWalletProviderAccount in getNonEmptyWalletProviderAccounts"
                                    :key="providerAccountNonEmptyWalletProviderAccount.id"
                                    :providerAccount="providerAccountNonEmptyWalletProviderAccount"
                                    class="Tickets-providerAccountNonEmptyWalletProviderAccount"
                                    v-bind="providerAccountNonEmptyWalletProviderAccount"
                                />
                            </ul>
                        </template>
                    </div>

                    <v-alert
                        v-else-if="config.header.template !== 'sytral'"
                        color="info"
                        density="compact"
                        prominent
                        text
                        variant="tonal"
                    >
                        {{ $t('fare_media:empty_product_list') }}
                    </v-alert>

                    <div v-else-if="config.header.template === 'sytral'">
                        <catalog-top-empty-provider-account-banner
                            :cardsHeight="null"
                            :cardsMaxWidth="null"
                            :maxWidthWrapper="'100%'"
                            :providers="[provider]"
                            @show-add-account-modal="triggerAddAccountEvent(provider)"
                            @show-subscription-modal="setShowSubscriptionModal(provider)"
                        />
                    </div>
                    <div
                        v-if="provider.walletAddFareMedia"
                        class="Tickets-addCard"
                    >
                        <v-btn
                            class="my-2 font-weight-bold"
                            color="primary"
                            variant="flat"
                            @click="onAddFareMediaButtonClick"
                        >
                            <v-icon class="IconCard Tickets-addCardIcon" />
                            {{ provider.walletAddFareMedia.label }}
                        </v-btn>
                    </div>

                    <user-account-subscription-button
                        :provider="provider"
                        @show-user-account-subscription-modal="setShowUserAccountSubscriptionModal"
                        @show-user-subscription-third-party-modal="setShowUserAccountThirdPartyModal"
                    />

                    <div class="d-flex justify-center">
                        <v-btn
                            v-if="config.features.account_association?.enabled && config.features.account_association?.add"
                            :to="{ name: 'accountAssociations' }"
                            color="primary"
                            variant="tonal"
                            class="my-2 px-2"
                        >
                            <span class="IconGroup mr-2" />
                            {{ $t('button:associate_an_account') }}
                        </v-btn>
                    </div>
                </v-window-item>
            </v-window>
        </template>
        <associated-account-list
            v-if="getWalletAssociatedAccounts.length > 0"
            :associatedAccounts="getWalletAssociatedAccounts"
            class="Tickets-associatedAccounts"
            @download-fare-media-certificate-button-clicked="downloadFareMediaCertificateButtonClicked"
            @suspend-subscription-button-clicked="suspendSubscriptionButtonClicked"
            @update-fare-media="openSupportReaderModal"
        />
        <modal
            v-model="validityDates.openModal"
            :title="$t('user:download_certificate:choose_dates')"
            @close-modal="closeModal"
        >
            <v-form @submit.prevent="submitValidityDatesForm">
                <v-container>
                    <v-row>
                        <v-col
                            cols="12"
                            sm="6"
                        >
                            <v-text-field
                                v-model="validityDates.startDate"
                                :error-messages="v$.startDate.$errors.map(e => e.$message)"
                                :label="$t('startDate')"
                                :max="validityDatesFormConstraints.maxDate"
                                :min="validityDatesFormConstraints.minDate"
                                type="date"
                                variant="underlined"
                                @update:model-value="v$.startDate.$touch()"
                            />
                        </v-col>
                        <v-col
                            cols="12"
                            sm="6"
                        >
                            <v-text-field
                                v-model="validityDates.endDate"
                                :error-messages="v$.endDate.$errors.map(e => e.$message)"
                                :label="$t('endDate')"
                                :max="validityDatesFormConstraints.maxDate"
                                :min="validityDatesFormConstraints.minDate"
                                type="date"
                                variant="underlined"
                                @update:model-value="v$.startDate.$touch()"
                            />
                        </v-col>
                    </v-row>
                    <v-alert
                        v-if="downloadFareMediaCertificateError"
                        color="error"
                        density="compact"
                        variant="tonal"
                        :text="downloadFareMediaCertificateError.message"
                    />
                </v-container>
            </v-form>
            <template #actions>
                <v-btn
                    variant="text"
                    @click="closeModal"
                >
                    {{ $t('button:cancel') }}
                </v-btn>
                <v-btn
                    class="bg-primary ml-2"
                    @click="submitFormValidityDates"
                >
                    {{ $t ('button:validate') }}
                </v-btn>
            </template>
        </modal>
        <modal
            v-model="validityDates.openSuccessMessageModal"
            :title="$t('wallet:contract:action:product_temporarily_suspend_subscription:success_title')"
        >
            <v-card-text>
                <div class="text-center">
                    <v-icon
                        color="success"
                        class="ValidityDatesSuccessModal-icon text-center mb-5"
                        icon="mdi mdi-check-circle-outline"
                    />
                </div>
                <v-alert
                    class="mb-0"
                    color="green"
                    density="compact"
                >
                    {{ $t('wallet:contract:action:product_temporarily_suspend_subscription:success') }}
                </v-alert>
            </v-card-text>
            <template #actions>
                <v-btn
                    class="bg-primary"
                    @click.prevent="closeSuccessMessageModal"
                >
                    {{ $t ('close_modal_carefully_label') }}
                </v-btn>
            </template>
        </modal>
        <div v-if="getWalletAssociatedAccounts">
            <div
                v-for="associatedAccounts in getWalletAssociatedAccounts"
                :key="associatedAccounts.id"
            >
                <div
                    v-for="providerAccount in associatedAccounts.providers"
                    :key="providerAccount.id"
                >
                    <div v-if="providerAccount.walletOptions.type === 'MOBILE'">
                        <p class="h2 mt-3">
                            {{ $t ('account:associated_accounts:title') }}
                        </p>
                        <device-list :provider="providerAccount" />
                    </div>
                </div>
            </div>
        </div>
        <fare-media-add-modal
            v-model="showFareMediaAddModal"
            :is-coming-from-fare-media-choice-modal="false"
            @go-next-action="fareMediaAddModalCloseAction"
            @close-modal="closeAllFareMediaChoiceOrAddModals"
        />
        <fare-media-add-new-modal
            v-model="showFareMediaAddNewModal"
            :is-coming-from-fare-media-choice-modal="false"
            @go-next-action="fareMediaAddNewModalCloseAction"
            @close-modal="closeAllFareMediaChoiceOrAddModals"
        />
        <fare-media-add-existant-modal
            v-model="showFareMediaAddExistantModal"
            :is-coming-from-fare-media-choice-modal="false"
            @go-next-action="fareMediaAddExistantModalCloseAction"
            @close-modal="closeAllFareMediaChoiceOrAddModals"
        />
        <user-account-subscription-modal v-model="showUserAccountSubscriptionModal" />
        <user-account-third-party-subscription-modal v-model="showUserAccountThirdPartyModal" />
        <product-modal />
        <support-reader-modal
            v-model="showSupportReaderModal"
            :headerTitle="$t('card_reader:scan_modal:title')"
            :operationType="'WRITE'"
            @operation-finished="onSupportReaderOperationFinished"
        />
    </div>
</template>

<script setup>
import PageConfigManager from '@/StoreWeb/managers/PageConfigManagerSingleton'
import { computed, onBeforeMount, onMounted, reactive, ref, watch } from 'vue'
import { useStore } from 'vuex'
import { isEmpty } from 'global-utils'
import { useI18n } from 'vue-i18n'
import emitter from 'global-emitter'
import FareMediaItem from '@/StoreWeb/components/fare-media/FareMediaItem'
import ETicketItem from '@/StoreWeb/components/eticket/ETicketItem'
import FareMediaAddExistantModal from '@/StoreWeb/components/fare-media/FareMediaAddExistantModal'
import FareMediaAddModal from '@/StoreWeb/components/fare-media/FareMediaAddModal'
import FareMediaAddNewModal from '@/StoreWeb/components/fare-media/FareMediaAddNewModal'
import FareProfile from '@/StoreWeb/components/fare-profile/FareProfile'
import ProviderAccountItem from '@/StoreWeb/components/fare-media/ProviderAccountItem'
import BlockLoader from '@/StoreWeb/components/common/BlockLoader'
import ProductModal from '@/StoreWeb/components/product/ProductModal'
import AssociatedAccountList from '@/StoreWeb/components/account/association/AssociatedAccountList'
import UserAccountSubscriptionModal from '@/StoreWeb/components/account/UserAccountSubscriptionModal'
import UserAccountSubscriptionButton from '@/StoreWeb/components/account/UserAccountSubscriptionButton'
import UserAccountThirdPartySubscriptionModal from '@/StoreWeb/components/account/UserAccountThirdPartySubscriptionModal'
import DeviceList from '@/StoreWeb/components/account/DeviceList'
import { useRoute } from 'vue-router'
import { required } from '@/StoreWeb/utils/i18n-validators'
import { useVuelidate } from '@vuelidate/core'
import moment from 'moment'
import * as userActionTypes from '@/StoreWeb/store/modules/user/action-types'
import { helpers } from '@vuelidate/validators'
import config from 'config'
import { useModalActions } from '@/StoreWeb/js/composables/add-faremedia-modals-utils'
import i18n from '@/StoreWeb/i18n'
import { triggerAddAccountEvent } from '@/StoreWeb/js/composables/msp-account-utils'
import SupportReaderModal from '@/StoreWeb/components/miscellaneous/conduent-support-reader/SupportReaderModal'
import { useCardReader } from '@/StoreWeb/js/composables/card-reader-utils'
import IcomoonIcon from '@/StoreWeb/components/common/IcomoonIcon.vue'

const store = useStore()
const route = useRoute()
const { t } = useI18n()
const {
    closeAllFareMediaChoiceOrAddModals,
    fareMediaAddExistantModalCloseAction,
    fareMediaAddModalCloseAction,
    fareMediaAddNewModalCloseAction,
    getFareMediaAddActionModal,
    setShowFareMediaAddModal,
    setShowFareMediaAddExistantModal,
    setShowFareMediaAddNewModal,
    showFareMediaAddExistantModal,
    showFareMediaAddModal,
    showFareMediaAddNewModal
} = useModalActions()
const {
    showSupportReaderModal,
    openSupportReaderModal,
    onSupportReaderOperationFinished
} = useCardReader()

const validityDates = reactive({
    actionType: null,
    openModal: false,
    openSuccessMessageModal: false,
    providerId: null,
    accountId: null,
    fareMediaId: null,
    fareMediaProductId: null,
    startDate: moment().format('YYYY-MM-DD'),
    endDate: moment().format('YYYY-MM-DD')
})
const validityDatesFormConstraints = reactive({
    minDate: moment().format('YYYY-MM-DD'),
    maxDate: moment().format('YYYY-MM-DD')
})
const rules = {
    startDate: {
        required,
        minDate: minDate(),
        maxDate: maxDate(),
        mustBeLessThanEndDate: helpers.withMessage(i18n.global.t('validations.mustBeLessThanEndDate'), value => {
            return new Date(value) < new Date(validityDates.endDate)
        })
    },
    endDate: {
        required,
        minDate: minDate(),
        maxDate: maxDate()
    }
}
const v$ = useVuelidate(rules, validityDates, { $scope: 'validity_dates' })
const pageConfigManager = reactive(PageConfigManager.getInstance())
const showUserAccountSubscriptionModal = ref(false)
const showUserAccountThirdPartyModal = ref(false)
const currentWalletId = ref(0)

const getWallet = computed(() => store.getters.getWallet)
const getUserStatus = computed(() => store.getters.getUserStatus)
const getWalletAssociatedAccounts = computed(() => store.getters.getWalletAssociatedAccounts)
const getNonEmptyWalletProviderAccounts = computed(() => store.getters.getNonEmptyWalletProviderAccounts)
const getWalletProviders = computed(() => store.getters.getWalletProviders)
const userPendingRequests = computed(() => store.state.userModule.userPendingRequests)
const downloadFareMediaCertificateError = computed(() => store.state.userModule.downloadFareMediaCertificateError)
const showWalletLoader = computed(() => userPendingRequests.value.getWallet)
const providersWithDevices = computed(() => {
    return getWalletProviders.value.filter(provider =>
        provider.walletOptions.type === 'MOBILE'
    )
})

watch(getWalletProviders, async () => {
    initCurrentWalletId()
})

onBeforeMount(() => {
    pageConfigManager.setConfig({
        title: 'account:tickets:title',
        currentAccountPage: 'tickets'
    })
})

onMounted(() => {
    initCurrentWalletId()
})

function combinedProviderData () {
    return getWalletProviders.value.flatMap(provider => {
        return provider.accounts.map(providerAccount => {
            return {
                provider,
                providerAccount
            }
        })
    })
}

function initCurrentWalletId () {
    if (!route.params.fareMediaId) return

    const walletProvidersData = combinedProviderData()
    const matchingWalletIndex = walletProvidersData.findIndex(({ providerAccount }) =>
        providerAccount.fareMedias.some(fareMedia => fareMedia.id === route.params.fareMediaId)
    )

    if (matchingWalletIndex !== -1) {
        currentWalletId.value = matchingWalletIndex
    }
}

function setShowUserAccountSubscriptionModal () {
    showUserAccountSubscriptionModal.value = true
}

function setShowUserAccountThirdPartyModal () {
    showUserAccountThirdPartyModal.value = true
}

function setShowSubscriptionModal (walletProvider) {
    const provider = getWalletProviders.value.find(provider => provider.id === walletProvider.id)
    if (provider.walletCreateAccount && provider.walletCreateAccount.mode === 'SYTRALSOUSCRIPTION') {
        setShowUserAccountSubscriptionModal()
    } else if (provider.walletCreateThirdPartyAccount && provider.walletCreateAccount.mode === 'SYTRALTHIRDPARTYSOUSCRIPTION') {
        setShowUserAccountThirdPartyModal()
    } else {
        emitter.emit('showAddFareMediaModal', { walletId: provider.id, formType: provider.walletAddAccount.mode })
    }
}

function downloadFareMediaCertificateButtonClicked (data) {
    validityDates.actionType = data.actionType
    validityDates.providerId = data.fareMedia.providerId
    validityDates.accountId = data.fareMedia.accountId
    validityDates.fareMediaId = data.fareMedia.id
    validityDates.providerUserExternalId = data.fareMedia.providerUserExternalId
    validityDates.providerUserId = data.fareMedia.providerUserId
    validityDatesFormConstraints.minDate = moment().subtract(1, 'year').format('YYYY-MM-DD')
    validityDatesFormConstraints.maxDate = moment().format('YYYY-MM-DD')
    validityDates.startDate = moment().subtract(1, 'year').format('YYYY-MM-DD')
    validityDates.endDate = moment().format('YYYY-MM-DD')
    validityDates.openModal = true
}

function suspendSubscriptionButtonClicked (data) {
    validityDates.actionType = data.actionType
    validityDates.providerId = data.providerId
    validityDates.accountId = data.accountId
    validityDates.fareMediaId = data.fareMediaId
    validityDates.fareMediaProductId = data.productId
    validityDatesFormConstraints.minDate = moment().format('YYYY-MM-DD')
    validityDatesFormConstraints.maxDate = null
    validityDates.startDate = moment().format('YYYY-MM-DD')
    validityDates.endDate = moment().add(1, 'month').format('YYYY-MM-DD')
    validityDates.openModal = true
}

function getFullName (providerAccount) {
    return `${providerAccount.firstName.toLowerCase().ucFirst()} ${providerAccount.lastName.toLowerCase().ucFirst()}`
}

async function submitFormValidityDates () {
    v$.value.$touch()
    const isValid = await v$.value.$validate()
    if (!v$.value.$invalid && isValid) {
        submitValidityDatesForm()
    }
}

async function submitValidityDatesForm () {
    if (validityDates.actionType === 'FARE_MEDIA_DOWNLOAD_CERTIFICATE') {
        await submitFareMediaCertificateDate()
    } else if (validityDates.actionType === 'PRODUCT_TEMPORARILY_SUSPEND_SUBSCRIPTION') {
        await submitSuspendSubscription()
    }
}

async function submitFareMediaCertificateDate () {
    await store.dispatch(userActionTypes.DOWNLOAD_FARE_MEDIA_CERTIFICATE, {
        providerId: validityDates.providerId,
        accountId: validityDates.accountId,
        fareMediaId: validityDates.fareMediaId,
        providerUserExternalId: validityDates.providerUserExternalId,
        providerUserId: validityDates.providerUserId,
        startDate: validityDates.startDate,
        endDate: validityDates.endDate
    })

    if (!downloadFareMediaCertificateError.value) {
        closeModal()
    }
}

async function submitSuspendSubscription () {
    await store.dispatch(userActionTypes.EXECUTE_ACTION_ON_WALLET_CONTRACT, {
        actionType: validityDates.actionType,
        providerId: validityDates.providerId,
        accountId: validityDates.accountId,
        fareMediaId: validityDates.fareMediaId,
        productId: validityDates.fareMediaProductId,
        startDate: moment(validityDates.startDate).toISOString(),
        endDate: moment(validityDates.endDate).toISOString()
    }).then(() => {
        closeModal()
        validityDates.openSuccessMessageModal = true
    }).catch(() => {
        closeModal()
    })
}

function closeModal () {
    validityDates.openModal = false
    v$.value.$reset()
    store.dispatch(userActionTypes.RESET_CERTIFICATE_MODAL_STATE)
}

function closeSuccessMessageModal () {
    validityDates.openSuccessMessageModal = false
}

function minDate () {
    return helpers.withMessage(
        () => t('validations.minDate') + moment(validityDatesFormConstraints.minDate).format(t('date_format')),
        value => {
            const valueToCompare = validityDatesFormConstraints.minDate
            if (valueToCompare === null) {
                return true
            }
            return moment(value).isSameOrAfter(valueToCompare)
        }
    )
}

function maxDate () {
    return helpers.withMessage(
        () => t('validations.maxDate') + moment(validityDatesFormConstraints.maxDate).format(t('date_format')),
        value => {
            const valueToCompare = validityDatesFormConstraints.maxDate
            if (valueToCompare === null) {
                return true
            }
            return moment(value).isSameOrBefore(valueToCompare)
        }
    )
}

function onAddFareMediaButtonClick () {
    switch (getFareMediaAddActionModal('conduent')) {
        case 'shouldShowFareMediaAddModal':
            setShowFareMediaAddModal()
            break
        case 'shouldShowFareMediaAddExistantModal':
            setShowFareMediaAddExistantModal()
            break
        case 'shouldShowFareMediaAddNewModal':
            setShowFareMediaAddNewModal()
            break
    }
}
</script>

<style lang="scss" scoped>
@import 'globalScss';

.rounded-tab {
    margin: 5px;
    padding: 10px 20px;
    border-radius: 12px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, .2) !important;
    background-color: #fff;
    text-transform: none;
    color: #333;
    transition: box-shadow, .2s, ease-in-out !important;
}

.rounded-tab:hover {
    border-radius: 12px;
    background-color: #e0e0e0;
}

.v-tab--active.rounded-tab {
    border-bottom: none !important;
    background-color: $primary-color;
    color: white;
}

.Tickets {
    &-tabIcon {
        font-size: 32px;
        color: $primary_color !important;
    }

    &-userWithAvatar {
        margin-bottom: 40px;
    }

    &-blockedUserAlert {
        margin-bottom: 30px;
    }

    &-subtitle {
        display: flex;
        align-items: center;
        gap: $s2;
    }

    &-subtitleText {
        @extend %h2;

        margin: 0;
    }

    &-category {
        margin-bottom: 40px;
    }

    &-list {
        margin: 0;
        padding: 0;
        list-style: none;

        &:last-child {
            margin-bottom: 0;
        }
    }

    &-addCard {
        margin: 0;
        font-size: 14px;
        font-weight: $fontWeight-defaultBold;
        text-align: center;

        &Link {
            display: inline-flex;
            align-items: center;
            cursor: pointer;
            color: $color-brandPrimary;
            transition: color .3s;

            &:hover,
            &:active {
                color: $color-brandPrimaryDark;
            }
        }

        &Icon {
            font-size: 28px;
        }
    }

    &-associatedAccounts {
        padding-top: 20px;
    }

    &-planList,
    &-counterList {
        display: flex;
        justify-content: flex-start;
        align-items: stretch;
        overflow-x: auto;
        margin: 0 -25px;

        &::-webkit-scrollbar {
            display: none;
        }

        &Item {
            display: inline-flex;
            flex-direction: column;
            justify-content: flex-start;
            min-width: 170px;
            max-width: 170px;
            margin: 0 10px 10px 0;

            &:first-child {
                margin-left: 25px;
            }

            &:last-child {
                margin-right: 25px;
            }
        }
    }

    &-fareMediaTitle {
        margin-top: 15px;
        font-family: $fontFamily-title;
        font-size: $font-xmedium;
        font-weight: $fontWeight-titleRegular;
        color: $color-defaultText;
    }

    &-alertMessage {
        font-size: 14px;
    }
}

.ValidityDatesSuccessModal-icon.v-icon {
    font-size: 70px;
}
</style>
