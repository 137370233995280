<template>
    <component
        :is="tag"
        class="FareMediaChoiceItem"
    >
        <a
            :class="isCurrent ? 'FareMediaChoiceItem-link--current' : ''"
            class="FareMediaChoiceItem-link"
            @click="selectFareMedia"
        >
            <span class="FareMediaChoiceItem-iconWrapper">
                <span
                    :class="getIcon"
                    class="FareMediaChoiceItem-icon"
                >
                    <span class="path1" />
                    <span class="path2" />
                    <span class="path3" />
                </span>
                <span
                    v-if="mdiIcon"
                    class="FareMediaChoiceItem-icon"
                    :class="mdiIcon"
                />
            </span>
            <span class="FareMediaChoiceItem-label">
                <strong class="FareMediaChoiceItem-title">{{ title }}</strong>
                <span class="FareMediaChoiceItem-subtitle">{{ subtitle }}</span>
            </span>
        </a>
    </component>
</template>

<script>
export default {
    props: {
        itemData: {
            type: Object,
            default: null
        },
        isCurrent: {
            type: Boolean,
            default: false
        },
        icon: {
            type: String,
            required: true
        },
        mdiIcon: {
            type: String,
            default: ''
        },
        id: {
            type: String,
            required: true
        },
        subtitle: {
            type: String,
            required: true
        },
        tag: {
            type: String,
            default: 'li'
        },
        title: {
            type: String,
            required: true
        }
    },
    computed: {
        getIcon () {
            return 'Icon' + this.icon + this.mdiIcon
        }
    },
    methods: {
        selectFareMedia () {
            this.$emit('fareMediaSelected', this.itemData !== null ? this.itemData : this.id)
        }
    }
}
</script>

<style lang="scss" scoped>
@import 'globalScss';

.FareMediaChoiceItem {
    margin-bottom: 15px;

    &:last-child {
        margin-bottom: 0;
    }

    &-link {
        display: flex;
        padding: 10px 10px 14px;
        border-radius: 6px;
        box-shadow: $box-shadow-light;
        background: $color-lightest;
        cursor: pointer;
        color: $color-lighterText;
        transition: background .3s;

        &:hover,
        &:active,
        &--current {
            background: $color-lightgray3;
        }
    }

    &:first-child {
        padding-top: 0;
        border-top: none;
    }

    &-iconWrapper {
        position: relative;
        min-width: 32px;
    }

    &-icon {
        position: absolute;
        font-size: 30px;
        color: $color-brandPrimary;
    }

    .Icon {
        &FareMediaIllust {
            top: 0;
            left: -2px;
            color: $color-lighterText;

            .path3 {
                color: $color-brandPrimary;
            }
        }

        &Card {
            top: -3px;
            left: -2px;
            color: $color-brandPrimary;
        }

        &Phone {
            top: 0;
            left: -2px;
        }
    }

    &-label {
        flex-grow: 1;
    }

    &-title {
        display: block;
        font-family: $fontFamily-title;
        font-size: 18px;
        font-weight: $fontWeight-titleBold;
        color: $color-defaultText;
    }

    &-subtitle {
        font-size: 12px;
        font-weight: $fontWeight-defaultMedium;
    }
}
</style>
