import config from 'config'
import ISO6391 from 'iso-639-1'
import store from '@/StoreWeb/store/store'
import i18n from 'i18n'
import cachios from 'cachios'
import router from '@/router/router'
import LoginManager from 'user/Login/managers/LoginManager'
import NavigationGuardManager from '@/StoreWeb/managers/NavigationGuardManagerSingleton'
import localeConversionTable from '@/StoreWeb/js/locales-conversion-table'
import * as cartMutationTypes from '@/StoreWeb/store/modules/cart/mutation-types'
import * as mainMutationTypes from '@/StoreWeb/store/modules/main/mutation-types'
import * as userMutationTypes from '@/StoreWeb/store/modules/user/mutation-types'
import { isEmpty } from 'global-utils'

const navigationGuardManager = NavigationGuardManager.getInstance()

export const defaultLocale = config.i18n.default_locale
export const supportedLocales = config.i18n.supported_locales

export const getBrowserLanguage = () => (navigator.language || navigator.userLanguage).substring(0, 2)

export const isSegmentBelongsToNonLocalizedRoute = (segment) => {
    return ['externalLinks', 'internalLinks'].includes(segment)
}

export const isLocale = (code) => ISO6391.validate(code)

export const isSupportedLocale = (code) => typeof supportedLocales[code] !== 'undefined'

export const applyLocale = (locale) => {
    i18n.locale = locale
    i18n.global.locale = locale
    document.documentElement.setAttribute('lang', locale)
    cachios.axiosInstance.defaults.headers.common['Accept-Language'] = localeConversionTable[i18n.locale]
    if (!config.web_harmony.header) {
        LoginManager.redirectUrl = `${window.location.origin}/${i18n.locale}/signedin`
    }
}

export const deduceLocale = (segment, isLocalizedRoute) => {
    const localStorageLocale = localStorage.getItem('locale')
    let selectedLocale = defaultLocale

    if (
        localStorageLocale &&
        isLocale(localStorageLocale) &&
        isSupportedLocale(localStorageLocale)
    ) {
        selectedLocale = localStorageLocale
    } else if (
        isLocalizedRoute &&
        isSupportedLocale(segment)
    ) {
        selectedLocale = segment
    } else if (
        !isLocalizedRoute ||
        !isLocale(localStorageLocale)
    ) {
        const browserLanguage = getBrowserLanguage()

        if (isSupportedLocale(browserLanguage)) {
            selectedLocale = browserLanguage
        }
    }

    return selectedLocale
}

export const resetStates = () => {
    store.commit(mainMutationTypes.SET_SHOW_MODAL, false)
    store.commit(mainMutationTypes.SET_SHOW_MAIN_NAV, false)
    store.commit(userMutationTypes.SET_SHOW_NOTIFICATIONS, false)
    store.commit(userMutationTypes.SET_SHOW_ACCOUNT_NAV, false)
    store.commit(userMutationTypes.SET_SHOW_ACCOUNT_NAV, false)
    store.commit(cartMutationTypes.SET_SHOW_CART, false)
}

export const updateLocalizedRouteSegmentsLocaleAndGetNewPath = (segments, newLocale) => {
    segments[1] = newLocale

    return segments.join('/')
}

export const resolveUnlocalizedLocalizedRouteSegmentsAndGetResolvedRoute = (segments, localeToApply) => {
    segments = [localeToApply, ...segments.slice(1)]

    const localizedPath = '/' + segments.join('/')

    const resolvedRoute = router.resolve(localizedPath)

    if (resolvedRoute.name !== 'error404') {
        return localizedPath
    }

    return false
}

export const resolveLocalizedRouteSegmentsAndGetResolvedRoute = (path) => {
    const [pathWithoutQueryParams] = path.split('?')
    const resolvedRoute = router.resolve(pathWithoutQueryParams)

    if (resolvedRoute.name !== 'error404') {
        return resolvedRoute
    }

    return false
}

export const checkAuthorizationsAndGetFinalRoute = (to) => {
    const isAuthorisedInGuestMode = navigationGuardManager.isAuthorisedInGuestModeRoute(to.name)
    const isAuthorizedInPaymentProcessingFlow = navigationGuardManager.isAuthorizedInPaymentProcessingRoute(to.name)
    const isAuthorizedWithEmptyCart = navigationGuardManager.isAuthorizedWithEmptyCartRoute(to.name)
    const isAuthorizedRoute = navigationGuardManager.isAuthorizedRoute(to.name)

    if (!isAuthorizedRoute) {
        return {
            changeForRoute: {
                name: 'home'
            }
        }
    }

    if (!isAuthorisedInGuestMode) {
        if (config.userweb.login_mode && config.userweb.login_mode === 'kas') {
            return {
                hasToLogIn: true
            }
        } else {
            return {
                changeForRoute: {
                    name: 'home'
                }
            }
        }
    } else if (!isEmpty(to.params) && !isEmpty(to.params.hasToRefresh)) {
        return {
            hasToRefresh: true
        }
    } else if (!isAuthorizedWithEmptyCart) {
        return {
            changeForRoute: {
                name: 'home'
            }
        }
    } else if (!isAuthorizedInPaymentProcessingFlow) {
        return {
            changeForRoute: {
                name: 'cartConfirmation'
            }
        }
    }

    return {
        allChecksPassed: true
    }
}

export const getError404Route = (locale) => {
    return {
        name: 'error404',
        params: {
            lang: locale
        },
        query: {
            path: window.location.href
        },
        meta: {
            layout: 'default'
        }
    }
}
