import { computed } from 'vue'
import { isEmpty } from 'global-utils'
import { useI18n } from 'vue-i18n'
import config from 'config'

export const useCartBasket = (props) => {
    const { t } = useI18n()

    const getBasketProducts = computed(() => {
        const basket = props.basket
        const products = basket.products || []

        const isValidBasket = !isEmpty(basket) &&
            (basket.basketType === 'FAREMEDIA' || basket.type === 'FAREMEDIA') &&
            basket.providerId &&
            basket.providerUserId &&
            basket.providerUserExternalId &&
            basket.fareMediaId
        if (isValidBasket && hasAddNewProductToBasketAction(products)) {
            const updatedProduct = getUpdatedProduct(products[0], basket)
            return [updatedProduct, ...products.slice(1)]
        }

        return products
    })
    const getFullName = computed(() => {
        if (props.basket?.ownerFirstName || props.basket?.ownerLastName) {
            return `${props.basket?.ownerFirstName ? props.basket.ownerFirstName + ' ' : ''}${props.basket?.ownerLastName ?? ''}`
        }

        if (props.basket?.providerUserFirstName || props.basket?.providerUserLastName) {
            return `${props.basket?.providerUserFirstName ? props.basket.providerUserFirstName + ' ' : ''}${props.basket?.providerUserLastName ?? ''}`
        }

        if (props.basket?.recipientUser?.firstName || props.basket?.recipientUser?.lastName) {
            return `${props.basket?.recipientUser?.firstName ? props.basket.recipientUser.firstName + ' ' : ''}${props.basket?.recipientUser?.lastName ?? ''}`
        }

        return ''
    })
    const getLabel = computed(() => {
        if (['PROVIDERFAREMEDIA', 'PROVIDERACCOUNTANDFAREMEDIA', 'PROVIDERACCOUNTANDDEVICE'].includes(props.basket.info)) {
            if (getFullName.value && getFullName.value !== 'anonymous anonymous') {
                return t('fare_media:card_of').replace('%cardOwner%', getFullName.value)
            }

            return t('fare_media:default')
        }

        return ''
    })
    const getSubLabel = computed(() => {
        const { type, info, fareMediaId } = props.basket

        if (type !== 'SALE') {
            return ''
        }

        if (
            (
                ['PROVIDERACCOUNTANDFAREMEDIA', 'PROVIDERFAREMEDIA'].includes(info) &&
                !isEmpty(fareMediaId)
            ) ||
            getFullName.value !== 'anonymous anonymous'
        ) {
            return `n° ${fareMediaId}`
        }

        return ''
    })
    const isCardIconDisplayed = computed(() => {
        return props.basket.info === 'PROVIDERACCOUNTANDFAREMEDIA' || props.basket.info === 'PROVIDERFAREMEDIA'
    })

    const getUpdatedProduct = (product, basket) => {
        return {
            ...product,
            actions: [{
                label: t('cart:fare_media_loading:title'),
                type: 'ADD_NEW_PRODUCT_TO_BASKET',
                route: {
                    name: config.landing_page === 'catalog' ? 'home' : 'catalog',
                    params: {
                        isTemporary: true,
                        providerId: props.basket.providerId,
                        providerUserId: props.basket.recipientUser?.associationId !== 0 ? props.basket.recipientUser.userId : props.basket.providerUserId,
                        providerUserExternalId: props.basket.recipientUser?.associationId !== 0 ? props.basket.recipientUser.userId : props.basket.providerUserExternalId,
                        fareMediaId: basket.fareMediaId
                    }
                }
            }]
        }
    }

    const hasAddNewProductToBasketAction = (products) => {
        return products.some(product =>
            product.item.actions.some(action => action.type === 'ADD_NEW_PRODUCT_TO_BASKET')
        )
    }

    return {
        getBasketProducts,
        isCardIconDisplayed,
        getLabel,
        getSubLabel
    }
}
