<template>
    <component
        :is="'li'"
        class="BankAccountItem"
    >
        <div class="BankAccountItem-header">
            <div class="BankAccountItem-bankName">
                {{ bankAccount.bankName }}
            </div>
            <v-menu
                v-if="getActions.length > 0"
                v-model="showActionsMenu"
                class="BankAccountItem-dropdownMenu"
            >
                <template #activator="{ props: activatorProps }">
                    <v-btn
                        :title="t('button:show_actions')"
                        class="BankAccountItem-showActionsButton opacity-100"
                        icon="mdi-dots-vertical"
                        variant="plain"
                        v-bind="activatorProps"
                    />
                </template>
                <v-list
                    v-if="getActions.length > 0"
                    class="BankAccountItem-actionList pa-1"
                    density="compact"
                >
                    <v-list-item
                        v-for="action in getActions"
                        :key="action.type"
                        base-color="primary"
                        class="BankAccountItem-actionItem pa-2"
                        @click.prevent="executeAction(action)"
                    >
                        <v-list-item-title class="BankAccountItem-actionTitle text-caption font-weight-bold">
                            {{ action.label }}
                        </v-list-item-title>
                    </v-list-item>
                </v-list>
            </v-menu>
        </div>
        <div class="BankAccountItem-body">
            <dl
                v-for="(cell, index) in getCells"
                :key="index"
                class="BankAccountItem-info"
            >
                <dt class="BankAccountItem-infoTitle">
                    {{ cell.title }}
                </dt>
                <dd class="BankAccountItem-infoValue">
                    {{ cell.value }}
                </dd>
            </dl>
        </div>
        <div class="BankAccountItem-footer">
            <template
                v-for="sepaMandate in getMandates"
                :key="sepaMandate.uniqueMandateReference"
            >
                <v-btn
                    v-if="sepaMandate.uniqueMandateReference !== null && sepaMandate.status === 'A'"
                    :aria-label="$t('sepa_mandate:download_button:label') + ' ' + $t('open_new_tab')"
                    :title="$t('sepa_mandate:download_button:label') + ' ' + $t('open_new_tab')"
                    append-icon="mdi-open-in-new"
                    class="BankAccountItem-button BankAccountItem-downloadMandateButton"
                    prepend-icon="mdi-arrow-collapse-down"
                    size="large"
                    @click="downloadMandate(sepaMandate.uniqueMandateReference)"
                >
                    {{ $t('sepa_mandate:download_button:label') }}
                </v-btn>
                <v-btn
                    v-for="action in getButtonActions"
                    :key="action.type"
                    :aria-label="$t('sepa_mandate:download_button:label')"
                    :append-icon="action.appendIcon"
                    :prepend-icon="action.prependIcon"
                    :title="$t('sepa_mandate:download_button:label')"
                    class="BankAccountItem-button BankAccountItem-signMandateButton"
                    size="large"
                    @click="executeAction(action)"
                >
                    {{ action.label }}
                </v-btn>
            </template>
        </div>
    </component>
</template>

<script setup>
import { isEmpty } from 'global-utils'
import * as paymentActionTypes from '@/StoreWeb/store/modules/payment/action-types'
import { computed, ref } from 'vue'
import { useStore } from 'vuex'
import { useI18n } from 'vue-i18n'

const store = useStore()
const { t } = useI18n()

const emit = defineEmits(['editBankAccount', 'signMandate'])

const props = defineProps({
    bankAccount: {
        type: Object,
        required: true
    },
    provider: {
        type: String,
        required: true
    }
})

const showActionsMenu = ref(false)

const debugInformationsDisplay = computed(() => store.state.debuggingModule.debugInformationsDisplay)
const getCells = computed(() => {
    const cells = []

    if (debugInformationsDisplay.value > 1) {
        cells.push({
            title: t('bank_account:id'),
            value: props.bankAccount.providerBankAccountIdentifier
        })
    }

    cells.push({
        title: t('bank_account:iban'),
        value: props.bankAccount.iban
    })
    cells.push({
        title: t('bank_account:bic'),
        value: props.bankAccount.bic
    })

    if (props.bankAccount.getSignatureDate()) {
        cells.push({
            title: t('bank_account:signature_date'),
            value: props.bankAccount.getSignatureDate()
        })
    }

    return cells
})
const getMandates = computed(() => !isEmpty(props.bankAccount.mandates) ? props.bankAccount.mandates : [])
const getActions = computed(() => {
    const actions = props.bankAccount.actions.filter(action => !['SIGN_MANDATE'].includes(action.type))
    return !isEmpty(actions) ? actions : []
})

const getButtonActions = computed(() => {
    return props.bankAccount.actions
        .filter(action => ['SIGN_MANDATE'].includes(action.type))
        .map(action => {
            if (action.type === 'SIGN_MANDATE') {
                action.prependIcon = 'mdi-script-text-outline'
            }

            return action
        })
})

const downloadMandate = (uniqueMandateReference) => {
    store.dispatch(paymentActionTypes.GET_MANDATE_BINARY, {
        providerId: props.provider,
        rum: uniqueMandateReference
    })
}

function executeAction (action) {
    if (action.type === 'UPDATE_BANK_INFORMATION') {
        editBankAccount()
    }
    if (action.type === 'SIGN_MANDATE') {
        signMandate()
    }
}

function editBankAccount () {
    emit('editBankAccount', props.bankAccount)
}

function signMandate () {
    emit('signMandate', props.bankAccount)
}
</script>

<style lang="scss" scoped>
@import 'globalScss';

.BankAccountItem {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    gap: $s3;
    padding: $s4;
    border-radius: map-get($rounded, 'xl');
    background: $color-lightgray1;
    color: $color-black;

    &:last-child {
        margin-bottom: 0;
    }

    &-header {
        display: flex;
        justify-content: space-between;
        align-items: baseline;
    }

    &-bankName {
        font-size: $font-smedium;
        font-weight: $fontWeight-defaultBold;
    }

    &-showActionsButton {
        align-self: flex-start;
        margin: $ns2 $ns2 0 0;

        &:hover,
        &:active {
            color: $primary_color;
        }
    }

    &-actionList {
        max-width: 80vw;
    }

    &-actionItem {
        min-height: 0 !important;
    }

    &-actionTitle {
        overflow: hidden;
        cursor: pointer;
        text-overflow: unset;
        white-space: unset;
        hyphens: none;
    }

    &-body {
        display: flex;
        flex-flow: column wrap;
        justify-content: flex-start;
        align-items: flex-start;
        gap: $s3;

        @media #{map-get($display-breakpoints, 'sm-and-up')} {
            flex-direction: row;
            gap: $s5;
        }
    }

    &-info {
        flex-grow: 1;
        flex-basis: 0;
        font-size: $font-xsmall;
    }

    &-infoTitle {
        color: $color-black-ultraLight;
    }

    &-infoValue {
        font-weight: $fontWeight-defaultMedium;
        color: $color-black-light;
    }

    &-button {
        color: $color-brandPrimary;
    }
}
</style>
