<template>
    <footer
        class="FooterBar"
        role="contentinfo"
    >
        <div class="FooterBar-container hidden-sm-and-down">
            <div class="flex-grow-0 pa-0">
                <router-link
                    :to="{ name: 'home' }"
                    :title="getAppTitle"
                    class="FooterBar-branding"
                    rel="home"
                >
                    <img
                        class="footerBar-logo"
                        :src="getFooterLogo"
                        alt="footer-logo"
                    >
                </router-link>
            </div>

            <nav
                class="FooterBar-nav"
                role="navigation"
            >
                <div
                    class="FooterBar-navList d-flex flex-wrap"
                >
                    <span
                        class="FooterBar-copyright"
                    >
                        &copy; {{ getCurrentYear() }} Sytral
                    </span>
                    <ul class="FooterBar-navLinks">
                        <li
                            v-for="(link, index) in footerMenuLinks"
                            :key="index"
                        >
                            <a
                                class="FooterBar-link"
                                :class="{ 'custom-tac-opener': link.action && link.action === 'actionCookiesPanel' }"
                                :href="link.action && link.action === 'actionCookiesPanel' ? null : sanitizeUrl(link.to)"
                                :aria-label="link.label"
                                @click="handleClick($event, link.action)"
                            >
                                {{ link.action === 'actionCookiesPanel' ? $t(link.label) : link.label }}
                            </a>
                        </li>
                    </ul>
                </div>
            </nav>

            <div class="FooterBar-socialNetwork d-inline-flex justify-center">
                <template
                    v-for="link in footerSocialNetworks"
                    :key="link.alt"
                >
                    <a
                        :aria-label="link.alt + ' ' + $t('open_new_tab')"
                        :title="link.alt + ' ' + $t('open_new_tab')"
                        :href="sanitizeUrl(link.href)"
                        target="_blank"
                        data-testid="SocialNetworks"
                    >
                        <v-img
                            :key="link.alt"
                            :src="getSocialNetworksImg(link.src)"
                            :alt="link.alt"
                            :title="link.title"
                            width="28"
                            height="28"
                        />
                    </a>
                </template>
            </div>
        </div>
        <!--      MOBILE VIEW       -->
        <v-row class="FooterBar-container-mobile FooterBar-Mobile justify-space-between hidden-md-and-up ma-auto">
            <v-col class="FooterBar-socialNetwork d-flex justify-center">
                <a
                    v-for="link in footerSocialNetworks"
                    :key="link.alt"
                    :href="sanitizeUrl(link.href)"
                    target="_blank"
                    :aria-label="link.alt + ' ' + $t('open_new_tab')"
                    :title="link.alt + ' ' + $t('open_new_tab')"
                    class="mx-1 mt-2"
                >
                    <v-img
                        :src="getSocialNetworksImg(link.src)"
                        :alt="link.alt"
                        :title="link.title"
                        width="28"
                        height="28"
                    />
                </a>
            </v-col>

            <v-row class="flex-grow-1 ma-4">
                <v-col class="d-flex align-center pa-0">
                    <router-link
                        :to="{ name: 'home' }"
                        :title="getAppTitle"
                        class="FooterBar-branding"
                        rel="home"
                    >
                        <img
                            class="footerBar-logo"
                            :src="getFooterLogo"
                            alt="footer-logo"
                        >
                    </router-link>
                </v-col>
                <v-col class="pa-0">
                    <nav class="d-flex justify-end FooterBar-navList pa-0 text-right">
                        <ul>
                            <li
                                v-for="(link, index) in footerMenuLinks"
                                :key="index"
                                class="FooterBar-linkContainer"
                            >
                                <div class="pa-0">
                                    <a
                                        class="FooterBar-link"
                                        :class="{ 'custom-tac-opener': link.action && link.action === 'actionCookiesPanel' }"
                                        :href="link.action && link.action === 'actionCookiesPanel' ? null : sanitizeUrl(link.to)"
                                        :aria-label="link.label"
                                        @click="handleClick($event, link.action)"
                                    >
                                        {{ link.label }}
                                    </a>
                                </div>
                            </li>
                        </ul>
                        <div
                            class="FooterBar-copyright"
                        >
                            <span class="FooterBar-copyright text-end">
                                &copy; {{ getCurrentYear() }} Sytral
                            </span>
                        </div>
                    </nav>
                </v-col>
            </v-row>
        </v-row>
    </footer>
</template>

<script setup>
import { sanitizeUrl } from '@braintree/sanitize-url'
import { isEmpty } from 'global-utils'
import config from 'config'
import { computed, onMounted } from 'vue'
import { useMenu } from '@/StoreWeb/js/composables/sytral/use-menu'

const { footerMenuLinks, footerSocialNetworks, loadRemoteMenus } = useMenu()

onMounted(() => {
    loadRemoteMenus()
})

const getAppTitle = computed(() => {
    return !isEmpty(config.app_title) ? config.app_title : ''
})

const getFooterLogo = computed(() => {
    return process.env.VUE_APP_NETWORK_ID
        ? process.env.VUE_APP_CDN_URL + 'img/' + process.env.VUE_APP_NETWORK_ID + '/footer-logo.svg'
        : process.env.VUE_APP_CDN_URL + 'img/default/footer-logo.svg'
})

function handleClick (event, action) {
    if (action === 'actionCookiesPanel') {
        event.preventDefault()
        actionCookiesPanel()
    }
}
function actionCookiesPanel () {
    if (window.tarteaucitron) {
        window.tarteaucitron.userInterface.openPanel()
    }
}

function getSocialNetworksImg (src) {
    return process.env.VUE_APP_CDN_URL + process.env.VUE_APP_NETWORK_ID + '/' + src
}
const getCurrentYear = () => {
    return new Date().getFullYear()
}
</script>

<style lang="scss" scoped>
@import 'globalScss';

.v-list {
    background: none !important;
}

.FooterBar {
    border-top: rem-calc(1) solid #e8e8e8;
    background: $footer_background_color;
    color: $footer_color;

    &-branding {
        display: block;
        margin: 6px 0;

        img {
            width: 91px;
            height: 70px;
        }
    }

    &-container {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        max-width: 1300px;
        margin: auto;
        padding: 24px 25px;

        @media (min-width: $tablet-breakpoint) {
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
        }

        &-mobile {
            flex-direction: column;
        }
    }

    &-linkContainer {
        min-height: 0 !important;
        margin-bottom: 12px;
        padding-right: 0;

        @media (max-width: $tablet-breakpoint) {
            margin-bottom: 3px;
        }
    }

    &-link {
        display: block;
        cursor: pointer;
        font-size: 13px;
        font-weight: normal;
        color: $footer_color !important;
        transition: all .3s;

        &:hover,
        &:active {
            text-decoration: underline;
            color: $footer_color;
        }
    }

    &-nav {
        margin-bottom: 20px;

        @media (min-width: $tablet-breakpoint) {
            justify-content: center;
            margin-bottom: 0;
        }
    }

    &-navLinks {
        display: flex;
        flex-wrap: wrap;

        li {
            margin-right: 22px;
        }

        @media (min-width: $tablet-breakpoint) {
            flex-direction: row;
        }
    }

    &-navList {
        flex-direction: column;
        min-height: 20px !important;
        margin: 0;
        padding: 0 40px;
        overflow-x: auto;
        background: $footer_background_color;
        list-style: none;
        white-space: nowrap;

        @media (min-width: 960px) {
            display: flex;
        }
    }

    &-navItem {
        margin: 0;

        &:last-child {
            margin-right: 0;
        }

        @media (min-width: $tablet-breakpoint) {
            margin-right: 30px;
        }
    }

    &-copyright {
        padding: 8px 0;
        font-size: 13px !important;
        color: $footer_color !important;

        @media (min-width: 960px) {
            margin-right: 20px;
        }
    }

    &-socialNetwork {
        gap: 30px;
        position: relative;
    }

    &-socialNetwork::before,
    &-socialNetwork::after {
        content: '';
        position: relative;
        top: 50%;
        width: 100%;
        height: 1px;
        background-color: #707173;
    }

    &-socialNetwork::before {
        left: 0;
        margin-left: -20px;
    }

    &-socialNetwork::after {
        right: 0;
        margin-right: -20px;
    }
}
</style>
