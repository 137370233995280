import * as mainMutationTypes from '@/StoreWeb/store/modules/main/mutation-types'
import * as anonymousMutationTypes from '@/StoreWeb/store/modules/anonymous/mutation-types'
import cachios from 'cachios'
import DebuggingManager from '@/StoreWeb/managers/DebuggingManagerSingleton'
import FareMediaNormalizer from '@/StoreWeb/normalizers/fare-media/FareMediaNormalizerSingleton'
import ErrorManager from '@/StoreWeb/managers/ErrorManager'
import { isEmpty } from 'global-utils'
import { getSelectedFareMediaCookie } from '@/StoreWeb/js/mixins/wallet-utils'
import config from 'config'

export default class {
    constructor () {
        this._debuggingManager = DebuggingManager.getInstance()
        this._fareMediaNormalizer = FareMediaNormalizer.getInstance()

        this._store = null
    }

    get debuggingManager () {
        return this._debuggingManager
    }

    get fareMediaNormalizer () {
        return this._fareMediaNormalizer
    }

    get store () {
        return this._store
    }

    set store (store) {
        this._store = store
    }

    /* =============================================================================================================
    =================================================== ACTIONS ====================================================
    ============================================================================================================= */
    getSupportContentAction (params) {
        return new Promise((resolve) => {
            this.store.commit(mainMutationTypes.SET_SHOW_GLOBAL_LOADER, { value: true })
            this.store.commit(anonymousMutationTypes.SET_ANONYMOUS_PENDING_REQUEST, { key: 'getSupportContent', value: true })

            if (this.debuggingManager.getMockActionState('getSupportContent')) {
                this.debuggingManager.getResponse('getSupportContent', (response) => {
                    this.store.commit(anonymousMutationTypes.SET_ANONYMOUS_PENDING_REQUEST, { key: 'getSupportContent', value: false })
                    this.store.commit(mainMutationTypes.SET_SHOW_GLOBAL_LOADER, { value: false })
                    const fareMedia = this.fareMediaNormalizer.normalize(response.data, {
                        providerId: params.providerId
                    })
                    resolve(fareMedia)
                })
            } else {
                let url = `${process.env.VUE_APP_API_URL}/wallet/providers/${params.providerId}/fareMedias/${params.fareMediaId}`

                const selectedFareMediaCookie = getSelectedFareMediaCookie()
                if (config.card_reader.enable_cad_mode && ((!isEmpty(selectedFareMediaCookie) && selectedFareMediaCookie.isCadMode) || params.isCadMode)) {
                    url += '?isPhysicalMedia=true'
                }

                return cachios
                    .get(url)
                    .then(response => {
                        this.store.commit(anonymousMutationTypes.SET_ANONYMOUS_PENDING_REQUEST, { key: 'getSupportContent', value: false })
                        this.store.commit(mainMutationTypes.SET_SHOW_GLOBAL_LOADER, { value: false })
                        const normalizerParams = {
                            providerId: params.providerId
                        }
                        if (!isEmpty(params.accountId)) {
                            normalizerParams.accountId = params.accountId
                        }
                        const fareMedia = this.fareMediaNormalizer.normalize(response.data, normalizerParams)
                        resolve(fareMedia)
                    })
                    .catch(error => {
                        this.store.commit(anonymousMutationTypes.SET_ANONYMOUS_PENDING_REQUEST, { key: 'getSupportContent', value: false })
                        this.store.commit(mainMutationTypes.SET_SHOW_GLOBAL_LOADER, { value: false })
                        if (params.checkOnlyIfFareMediaExists) {
                            resolve(false)
                        } else {
                            this.store.commit(anonymousMutationTypes.SET_SUPPORT_CONTENT, null)
                            ErrorManager.displayErrorModal('anonymous:get_support_content', error)
                            resolve(null)
                        }
                    })
            }
        })
    }

    getSupportInfosAction (params) {
        return new Promise((resolve) => {
            this.store.commit(mainMutationTypes.SET_SHOW_GLOBAL_LOADER, { value: true })
            this.store.commit(anonymousMutationTypes.SET_ANONYMOUS_PENDING_REQUEST, { key: 'getSupportInfos', value: true })

            if (this.debuggingManager.getMockActionState('getSupportInfos')) {
                this.debuggingManager.getResponse('getSupportInfos', (response) => {
                    this.store.commit(anonymousMutationTypes.SET_ANONYMOUS_PENDING_REQUEST, { key: 'getSupportInfos', value: false })
                    this.store.commit(mainMutationTypes.SET_SHOW_GLOBAL_LOADER, { value: false })
                    const fareMedia = this.fareMediaNormalizer.normalize(response.data, {
                        providerId: params.providerId
                    })
                    resolve(fareMedia)
                })
            } else {
                let url = `${process.env.VUE_APP_API_URL}/wallet/providers/${params.providerId}/faremedia-validation/${params.fareMediaId}`

                const selectedFareMediaCookie = getSelectedFareMediaCookie()
                if (config.card_reader.enable_cad_mode && ((!isEmpty(selectedFareMediaCookie) && selectedFareMediaCookie.isCadMode) || params.isCadMode)) {
                    url += '?isPhysicalMedia=true'
                }

                return cachios
                    .get(url)
                    .then(response => {
                        this.store.commit(anonymousMutationTypes.SET_ANONYMOUS_PENDING_REQUEST, { key: 'getSupportInfos', value: false })
                        this.store.commit(mainMutationTypes.SET_SHOW_GLOBAL_LOADER, { value: false })
                        resolve({
                            isValid: true
                        })
                    })
                    .catch(error => {
                        // Dont display error modal for these error codes because it doesnt block the process
                        if (params.isForConsultation && ['FAREMEDIA_FULL', 'FAREMEDIA_EXPIRED'].includes(error?.response?.data?.errorCode)) {
                            this.store.commit(anonymousMutationTypes.SET_ANONYMOUS_PENDING_REQUEST, { key: 'getSupportInfos', value: false })
                            this.store.commit(mainMutationTypes.SET_SHOW_GLOBAL_LOADER, { value: false })
                            resolve({
                                isValid: true
                            })
                        } else {
                            this.store.commit(anonymousMutationTypes.SET_ANONYMOUS_PENDING_REQUEST, { key: 'getSupportInfos', value: false })
                            this.store.commit(mainMutationTypes.SET_SHOW_GLOBAL_LOADER, { value: false })
                            this.store.commit(anonymousMutationTypes.SET_SUPPORT_CONTENT, null)

                            if (!params.silentError) {
                                ErrorManager.displayErrorModal('anonymous:get_support_content', error)
                            }

                            resolve({
                                isValid: false,
                                error: error?.response?.data
                            })
                        }
                    })
            }
        })
    }
}
