<template>
    <v-card
        :class="[{ 'FareMediaItem--withProfile': fareMedia.profile !== null }]"
        class="FareMediaItem pa-3"
    >
        <div class="FareMediaItem-header justify-space-between mb-4">
            <div class="FareMediaItem-title">
                <div class="FareMediaItem-label">
                    {{ getLabel }}
                </div>
                <div
                    v-if="getSubLabel"
                    class="FareMediaItem-subLabel"
                >
                    {{ getSubLabel }}
                </div>
                <div
                    class="FareMediaItem-expiration"
                    v-html="$sanitize(getValidityEndDate)"
                />
                <div
                    v-if="fareMedia.profile !== null"
                    class="FareMediaItem-profile"
                >
                    <span>{{ fareMedia.profile }}</span>
                </div>
            </div>
            <div>
                <v-chip
                    :class="[`FareMediaItem-status--${fareMedia.status?.id}`]"
                    :color="fareMediaStatus.color"
                    :link="fareMediaStatus.link"
                    :prepend-icon="fareMediaStatus.icon"
                    :size="$vuetify.display.mdAndUp ? 'large' : 'default'"
                    :variant="fareMediaStatus.style"
                    class="FareMediaItem-status rounded-lg"
                    label
                    @click="fareMediaStatus.action"
                >
                    {{ fareMedia.status.label }}
                </v-chip>
            </div>
            <v-menu
                v-if="isActionMenuAvailable"
                v-model="showActionsMenu"
            >
                <template #activator="{ props: activatorProps }">
                    <v-btn
                        :title="t('button:show_actions')"
                        class="FareMediaItem-showActionsButton opacity-100"
                        icon="mdi-dots-vertical"
                        variant="plain"
                        v-bind="activatorProps"
                    />
                </template>
                <v-list
                    class="FareMediaItem-actionList pa-1"
                    density="compact"
                >
                    <template
                        v-for="(action, index) in actions"
                        :key="index"
                    >
                        <v-list-item
                            v-if="action.href"
                            :href="sanitizeUrl(action.href)"
                            :value="index"
                            target="_blank"
                            base-color="primary"
                            class="FareMediaItem-actionItem pa-2"
                        >
                            <v-list-item-title class="FareMediaItem-actionTitle text-caption font-weight-bold">
                                {{ action.label }}
                                <v-icon
                                    v-if="action.externalUrl"
                                    :aria-label="$t('open_new_tab')"
                                    :aria-hidden="false"
                                    class="pl-1"
                                    color="primary"
                                    size="medium"
                                >
                                    mdi-open-in-new
                                </v-icon>
                            </v-list-item-title>
                        </v-list-item>
                        <v-list-item
                            v-else-if="typeof action.onClick === 'function'"
                            :value="index"
                            base-color="primary"
                            class="FareMediaItem-actionItem pa-2"
                            @click.prevent="action.onClick"
                        >
                            <v-list-item-title class="FareMediaItem-actionTitle text-caption font-weight-bold">
                                {{ action.label }}
                            </v-list-item-title>
                        </v-list-item>
                    </template>
                </v-list>
            </v-menu>
        </div>
        <ul
            v-if="getFareMediaProducts.length"
            class="FareMediaItem-productList mb-4"
        >
            <li
                v-for="(fareMediaProduct, index) in getFareMediaProducts"
                :key="index"
            >
                <v-card
                    class="FareMediaItem-productContainer position-static"
                    elevation="0"
                >
                    <fare-media-product-item
                        :fareMediaProduct="fareMediaProduct"
                        :fareMedia="fareMedia"
                        :showActions="showActions"
                        class="FareMediaItem-productItem"
                        @suspend-subscription-button-clicked="suspendSubscriptionButtonClicked"
                    />
                </v-card>
            </li>
        </ul>
        <div
            v-if="isActionExists('FARE_MEDIA_RELOAD')"
            class="FareMediaItem-actions"
        >
            <v-btn
                color="primary"
                size="small"
                class="FareMediaItem-footer-reloadButton"
                @click="loadFareMedia"
            >
                {{ $t('fare_media:reload') }}
            </v-btn>
        </div>
        <v-btn
            v-if="FareMedia.activeStatuses.includes(fareMedia.status?.id) && !fareMedia.getIsExpired()"
            class="FareMediaItem-reloadButton font-weight-bold"
            color="primary"
            size="large"
            @click="setSupportAndGoCatalog(fareMedia)"
        >
            <v-icon
                icon="mdi-cart-arrow-down"
                class="mr-1"
            />
            {{ $t('action_button:reload_card') }}
        </v-btn>
    </v-card>
</template>

<script setup>
import { sanitizeUrl } from '@braintree/sanitize-url'
import FareMediaProductItem from '@/StoreWeb/components/fare-media/FareMediaProductItem'
import * as userMutationTypes from '@/StoreWeb/store/modules/user/mutation-types'
import { isEmpty } from 'global-utils'
import { useStore } from 'vuex'
import { computed, ref } from 'vue'
import { useRouter } from 'vue-router'
import { getSelectedFareMediaCookie, setSelectedFareMediaCookie } from '@/StoreWeb/js/mixins/wallet-utils'
import emitter from 'global-emitter'
import { useI18n } from 'vue-i18n'
import moment from 'moment'
import config from 'config'
import { formatAction } from '@/StoreWeb/js/composables/user-action-utils'
import FareMedia from '@/StoreWeb/models/user/wallet/FareMedia'
import * as internalActionTypes from '@/StoreWeb/store/modules/application-session/internal-action-types'
import * as externalActionTypes from '@/StoreWeb/store/modules/application-session/external-action-types'
import * as applicationSessionMutationTypes from '@/StoreWeb/store/modules/application-session/mutation-types'
import * as applicationSessionActionTypes from '@/StoreWeb/store/modules/application-session/action-types'

const emit = defineEmits(['downloadFareMediaCertificateButtonClicked', 'suspendSubscriptionButtonClicked', 'updateFareMedia'])

const props = defineProps({
    fareMedia: {
        type: Object,
        required: true
    },
    showActions: {
        type: Boolean,
        default: true
    },
    tag: {
        type: String,
        default: 'li'
    }
})

const router = useRouter()
const store = useStore()
const { t, locale } = useI18n()

const showActionsMenu = ref(false)

const actions = computed(() => {
    if (!isEmpty(props.fareMedia.actions)) {
        return props.fareMedia.actions.map(action => formatAction(action, setActionClick))
    }

    return []
})
const applicationSession = computed(() => store.state.applicationSessionModule.applicationSession)
const currentRoute = computed(() => store.state.currentRoute)
const fareMediaStatus = computed(() => {
    const status = {
        action: null,
        link: null,
        style: 'tonal'
    }

    switch (props.fareMedia.status.id) {
        case 'ACTIVE':
            status.color = 'info'
            status.icon = 'mdi-check-circle'
            break
        case 'TO_UPDATE':
            status.color = 'warning'
            status.icon = 'mdi-alert-circle-outline'
            break
        case 'FULL':
            status.color = 'warning'
            status.icon = 'mdi-alert-circle-outline'
            break
        default:
            status.color = 'error'
            status.icon = 'mdi-close-circle-outline'
            break
    }

    if (props.fareMedia.actions.some(action => action.type === 'FARE_MEDIA_UPDATE')) {
        status.action = updateFareMedia
        status.link = true
        status.style = 'flat'
    }

    return status
})
const isActionMenuAvailable = computed(() => {
    return props.showActions && actions.value.length > 0
})
const isMobileApp = computed(() => store.state.isMobileApp)
const isMobileOrTablet = computed(() => store.state.isMobileOrTablet)

const isActionExists = (actionType) => {
    return actions.value.some(action => action.type === actionType)
}

const getValidityEndDate = computed(() => {
    return t('date:expire_on')
        .replace(
            '%date%',
            `<strong>${moment(props.fareMedia.validityEndDate).format(t('date_format:default'))}</strong>`
        )
})

const getFareMediaProducts = computed(() => !isEmpty(props.fareMedia.products) ? props.fareMedia.products : [])
const getFullName = computed(() => {
    const recipientUser = props.fareMedia.recipientUser
    const fullName = (props.fareMedia.firstName || recipientUser?.firstName) && (props.fareMedia.lastName || recipientUser?.lastName)
        ? `${(props.fareMedia.firstName || recipientUser?.firstName).toLowerCase().ucFirst()} ${(props.fareMedia.lastName || recipientUser?.lastName).toLowerCase().ucFirst()}`
        : ''

    return fullName
})
const getLabel = computed(() => {
    if (getFullName.value) {
        return t('fare_media:card_of').replace('%cardOwner%', getFullName.value)
    }

    return t('fare_media:card_number').replace('%cardNumber%', props.fareMedia.id)
})
const getSubLabel = computed(() => {
    if (getFullName.value) {
        return `n° ${props.fareMedia.id}`
    }

    return ''
})

function setActionClick (formattedAction, action) {
    switch (action.type) {
        case 'FARE_MEDIA_DOWNLOAD_CERTIFICATE':
            formattedAction.onClick = () => downloadFareMediaCertificateButtonClicked(action)
            break
        case 'FARE_MEDIA_SUSPEND_SUBSCRIPTION':
            formattedAction.onClick = () => suspendSubscriptionButtonClicked(action)
            break
        case 'FARE_MEDIA_RENEW':
            formattedAction.onClick = () => renewFareMedia(action)
            break
        case 'FARE_MEDIA_REBUILD':
            formattedAction.onClick = () => rebuildFareMedia(action)
            break
        default:
            if (action?.parameters?.URI) {
                formattedAction.href = action.parameters.URI

                if (!isEmpty(action.external)) {
                    formattedAction.externalUrl = action.external
                }
            }
            break
    }
}

function loadFareMedia () {
    router
        .push(
            {
                name: 'fareMediaLoading',
                params: {
                    providerId: props.fareMedia.providerId,
                    providerUserId: props.fareMedia.accountId,
                    providerUserExternalId: props.basket.providerUserExternalId,
                    id: props.fareMedia.id
                }
            }
        )
}

function downloadFareMediaCertificateButtonClicked (action) {
    emit('downloadFareMediaCertificateButtonClicked', {
        actionType: action.type,
        fareMedia: props.fareMedia
    })
}

function rebuildFareMedia (action) {
    if (action?.parameters?.TEXT) {
        router.push({
            name: 'product',
            params: {
                id: action.parameters.TEXT
            }
        })
    }
}

function renewFareMedia (action) {
    const providerConfig = config.providers.find(provider => provider.id === props.fareMedia.providerId)

    if (
        providerConfig?.fare_media?.actions &&
        action.type in providerConfig.fare_media.actions &&
        providerConfig.fare_media.actions[action.type]?.link
    ) {
        window.location.href = providerConfig.fare_media.actions[action.type].link[locale.value]
    }
}

function suspendSubscriptionButtonClicked (action) {
    emit('suspendSubscriptionButtonClicked', action)
}

function setSupportAndGoCatalog (fareMedia) {
    store.commit(userMutationTypes.SET_SELECTED_SUPPORT, fareMedia)

    const selectedFareMediaCookie = getSelectedFareMediaCookie()

    const supportContentCookie = {
        fareMediaId: fareMedia.id,
        providerId: fareMedia.providerId,
        providerUserExternalId: fareMedia.providerUserExternalId,
        providerUserId: fareMedia.providerUserId
    }

    if (fareMedia?.isTemporary) {
        supportContentCookie.isTemporary = fareMedia.isTemporary
    }

    const isCadMode = selectedFareMediaCookie?.fareMediaId === fareMedia.id && selectedFareMediaCookie?.isCadMode

    setSelectedFareMediaCookie(supportContentCookie, isCadMode)
    emitter.emit('selectedSupportChanged')
    router.push({ name: 'catalog' })
}

function getFareMediaInfosForNfcReloading () {
    const fareMedia = {
        fareMediaId: props.fareMedia.id,
        providerId: props.fareMedia.providerId
    }

    if (props.fareMedia.firstName) {
        fareMedia.firstName = props.fareMedia.firstName
    }

    if (props.fareMedia.lastName) {
        fareMedia.lastName = props.fareMedia.lastName
    }

    return fareMedia
}

async function updateThroughNfc () {
    applicationSession.value.data.waitingForReloadingFareMedias = [getFareMediaInfosForNfcReloading()]

    let baseUrl

    if (currentRoute.value?.name === 'walletLight') {
        baseUrl = `${window.location.origin}/internalLinks/${internalActionTypes.GO_TO_WALLET_LIGHT}?sessionId=${applicationSession.value.id}&initialRequest=${externalActionTypes.RELOAD_FARE_MEDIA_THROUGH_NFC}`
    } else {
        baseUrl = `${window.location.origin}/internalLinks/${internalActionTypes.GO_TO_WALLET}?sessionId=${applicationSession.value.id}&initialRequest=${externalActionTypes.RELOAD_FARE_MEDIA_THROUGH_NFC}`
    }

    applicationSession.value.sessionContext = {
        currentStep: baseUrl,
        resumeStepSuccess: `${baseUrl}&status=success`,
        resumeStepError: `${baseUrl}&status=error`
    }

    store.commit(applicationSessionMutationTypes.SET_APPLICATION_SESSION, applicationSession.value)
    await store.dispatch(applicationSessionActionTypes.UPDATE_APPLICATION_SESSION)

    const externalLink = router.resolve({
        name: 'externalLinks',
        params: { action: externalActionTypes.RELOAD_FARE_MEDIA_THROUGH_NFC },
        query: { sessionId: applicationSession.value.id }
    })

    if (!isEmpty(externalLink)) {
        window.location.href = window.location.origin + externalLink.href
    }
}

function showUnaibleToUpdateModal (message) {
    emitter.emit('showAlertModal', {
        message,
        title: t('fare_media:update_modal:title')
    })
}

async function updateFareMedia () {
    if (isMobileApp.value) {
        if (isEmpty(applicationSession.value?.data?.isNfcSupported) || !applicationSession.value.data.isNfcSupported) {
            showUnaibleToUpdateModal(t('fare_media:update_modal:app_message'))

            return
        }

        updateThroughNfc()

        return
    }

    if (isMobileOrTablet.value) {
        showUnaibleToUpdateModal(t('fare_media:update_modal:message'))

        return
    }

    emit('updateFareMedia', props.fareMedia)
}
</script>

<style lang="scss" scoped>
/* stylelint-disable */
@import 'globalScss';

.FareMediaItem {
    position: relative;
    margin-bottom: $s5;
    border-radius: 15px !important;
    background: $color-lightest;
    color: $color-lightText;

    &-productContainer {
        height: 100%;
    }

    &-productItem {
        height: 100%;
        border-radius: 15px !important;
        background: linear-gradient(348.76deg, rgba(219, 191, 255, .1354) 13.95%, rgba(196, 208, 255, .16) 88.25%);
    }

    &-header {
        display: flex;
        justify-content: space-between;
        gap: 5px;
    }

    &-title {
        flex-grow: 1;
    }

    &-label {
        display: block;
        font-family: $fontFamily-title;
        font-size: $font-medium;
        font-weight: $fontWeight-titleBold;
        color: $color-defaultText;
    }

    &-subLabel {
        font-size: $font-xxsmall;
        font-weight: $fontWeight-defaultMedium;
        color: $color-lighterText;
    }

    &-profile {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        min-height: 26px;
        font-size: 12px;
        font-weight: $fontWeight-defaultMedium;
        color: $color-lighterText;

        &ChangeButton {
            display: inline-flex;
            justify-content: center;
            align-items: center;
            padding: 4px;
            cursor: pointer;
            color: $color-lighterText;
            transition: color .5s;

            &:hover,
            &:focus {
                color: $color-lightText;
            }
        }

        &ChangeButtonIcon {
            font-size: 18px;
        }
    }

    &-status {
        font-weight: bold;

        &--TO_UPDATE {
            cursor: pointer;
        }
    }

    &-expiration {
        margin-top: $s2;
        font-size: $font-xsmall;
        font-weight: $fontWeight-defaultMedium;
        color: $color-lighterText;
    }

    &-showActionsButton {
        margin-top: $ns1;
        margin-right: $ns2;

        &:hover,
        &:active {
            color: $primary_color;
        }
    }

    &-actionList {
        max-width: 80vw;
    }

    &-actionItem {
        min-height: 0 !important;
    }

    &-actionTitle {
        overflow: hidden;
        cursor: pointer;
        text-overflow: unset;
        white-space: unset;
        hyphens: none;
    }

    &-body {
        border-top: 1px solid $color-lightgray3;
    }

    &-actions {
        display: flex;
        flex-direction: column;
        gap: 15px;
        padding: 0 15px 15px;

        @media (min-width: $tablet-breakpoint) {
            flex-flow: row wrap;
            justify-content: flex-end;
            padding-right: 65px;
            padding-left: 25px;
        }
    }

    &-reloadButton {
        width: max-content;
    }

    &-footer {
        padding: 15px;
        border-top: 1px solid $color-lightgray3;

        @media (min-width: $tablet-breakpoint) {
            padding-right: 25px;
            padding-left: 25px;
        }
    }

    &-productList {
        display: grid;
        grid-template-columns: 1fr;
        gap: $s3;
        margin: 0;
        padding: 0;
        list-style: none;

        @media (min-width: $tablet-breakpoint) {
            grid-template-columns: repeat(2, 1fr);
        }

        @media (min-width: $desktop-breakpoint) {
            grid-template-columns: repeat(3, 1fr);
        }
    }

    &--activeStatus {
        .FareMediaItem-status {
            color: $primary_color;
        }
    }

    &--withProfile {
        .FareMediaItem-header {
            align-items: flex-start;
        }
    }
}
</style>
